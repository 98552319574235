import * as React from "react"
import { Helmet } from "react-helmet"
import { StaticQuery, graphql, Link } from "gatsby"

const Thanks = () => {

    return (
        <StaticQuery
            query={graphql`
            query {
            image: file(relativePath: { eq: "thanks.svg" }) {
                relativePath
                publicURL
            }

             
                } 
        `}
            render={(data) => {
                return (
                    <>
                        <Helmet>
                            <meta charSet="utf-8" />
                            <title>Thanks</title>
                        </Helmet>
                        <section className="maincontainer risorse-lista ">
                            <img style={{maxWidth:'500px', margin:'2rem auto', width:'100%', display:'block'}} src={data.image.publicURL} />
                            <p style={{textAlign:'center', marginTop:'3rem'}}>Il tuo contributo è importante! <br /> Esamineremo al più presto la tua segnalazione e ti contatteremo</p>
                            <Link to='/'><h1 style={{textAlign:'center', marginTop:'3rem'}}>Torna alla Home!</h1></Link>
                        </section>
                    </>)
            }

            }
        />

    )
}

export default Thanks